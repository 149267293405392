import React, { Fragment, useEffect, useState } from 'react';
import * as styles from './roadmap.module.scss';
import GoogleMapReact from 'google-map-react';
import { TranslationProvider } from '@/src/contexts/translation.context';
import { TranslationService } from '@/src/services/translation.service';
import mapStyle from '@/assets/map.json';
import { DepartureModel } from '@/src/models/departures.model';
import { getDirectionsService } from '@/src/services/directions.service';
import areIntervalsOverlappingWithOptions from 'date-fns/esm/fp/areIntervalsOverlappingWithOptions/index.js';

type RoadMapProps = {
  translation: TranslationService;
  timetable: DepartureModel[];
};

export default function RoadMap({ translation, timetable }: RoadMapProps) {
  const [error, setError] = useState(null);
  const [waypoints, setWaypoints] = useState([]);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);

  useEffect(() => {
    if(timetable) {
      const tempWaypoints = timetable[0].parts.map((parts) => {
        return parts.SequenceFragment.map((fragment, i) => {
          if(i === 0 || parts.SequenceFragment.length === i + 1) {
            return {
              stopAreaGid: fragment.StopPoint.IsIncludedInStopAreaGid,
              location: { lat: fragment.StopPoint.LocationNorthingCoordinate, lng: fragment.StopPoint.LocationEastingCoordinate },
              stopover: true
            };
          }
        });
      });

      const mergedWaypoints = [].concat.apply([], tempWaypoints);
      let filteredWaypoints = mergedWaypoints.filter(function(el) {
        return el != null;
      });

      filteredWaypoints = filteredWaypoints.filter((el, index) => {
        if(index === 0) {
          return true;
        } else {
          const prevWaypoint = filteredWaypoints[index - 1];
          return prevWaypoint.stopAreaGid !== el.stopAreaGid;
        }
      });

      const origin = filteredWaypoints[0];
      setOrigin(origin);

      const destination = filteredWaypoints[filteredWaypoints.length - 1];
      setDestination(destination);

      filteredWaypoints.pop();
      filteredWaypoints.shift();
      setWaypoints(filteredWaypoints);

    } else {
      setError('Can\'t find any timetable');
    }
  }, [timetable]);

  const onMapLoaded = (map: any, maps: any) => {
    map.setOptions({
      styles: mapStyle
    });

    const formattedWaypoints = waypoints.map((w) => {
      return {
        location: new maps.LatLng(w.location.lat, w.location.lng),
        stopover: w.stopover
      };
    });

    (async () => {
      const directionsService = getDirectionsService();
      const result = await directionsService.getImageUrl({
        departureAreaGid: origin.stopAreaGid,
        arrivalAreaGid: destination.stopAreaGid,
        maps: maps,
        departurePoint: {
          lat: origin.location.lat,
          lng: origin.location.lng
        },
        arrivalPoint: {
          lat: destination.location.lat,
          lng: destination.location.lng
        },
        formattedWaypoints: formattedWaypoints
      });

      const directionsRenderer = new maps.DirectionsRenderer();
      directionsRenderer.setMap(map);
      directionsRenderer.setDirections(JSON.parse(result));
    })();
  };

  return (
    <Fragment>
      {(origin && destination) &&
        <TranslationProvider value={translation}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBb6Wqpv7bvF42qoBK8_Vu_q5dc6teaNdA', language: translation.lang }}
            defaultCenter={{ lat: 60.197830, lng: 19.948906 }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
            defaultZoom={9}>
          </GoogleMapReact>
        </TranslationProvider>
      }
    </Fragment>
  );
}