import React from 'react';
import * as styles from './spinner.module.scss';

type SpinnerProps = {
  dark?: boolean;
}

export default function Spinner({ dark = false}: SpinnerProps) {
  return (
    <div className={`${styles.ldsDualRing} ${dark ? styles.dark : ''}`}></div>
  );
}