import React, { useState } from 'react';
import * as styles from './travel-search-dropdown.module.scss';
import { TravelSearchResult } from '@/src/services/travel.service';
import { useTranslation } from '@/src/contexts/translation.context';
import { useEffect } from 'react';

export type OnSelectEvent = {
  targetType: 'activity' | 'stopArea',
  gid: string
};

type TravelSearchDropdownProps = {
  result: TravelSearchResult;
  onSelect?: (event: OnSelectEvent) => any;
  hasFocus?: boolean;
};

type TravelSearchItemProps = {
  title: string;
  onClick?: () => any;
}

function TravelSearchItem({ title, onClick }: TravelSearchItemProps) {
  return (
    <button type="button" className={styles.travelItem} onClick={onClick}>
      {title}
    </button>
  );
}

export function TravelSearchDropdown({ result, onSelect, hasFocus }: TravelSearchDropdownProps) {
  const translation = useTranslation();

  return (
    <div className={`${styles.container} ${!hasFocus ? styles.hidden : ''}`}>
      {result.activities.map(activity => (
        <TravelSearchItem 
          key={activity.id}
          title={activity.title[translation.lang]}
          onClick={() => onSelect({ targetType: 'activity', gid: activity.id })}></TravelSearchItem>
      ))}

      {result.stopAreas.map(stopArea => (
        <TravelSearchItem
          key={stopArea.Id}
          title={stopArea.Name}
          onClick={() => onSelect({ targetType: 'stopArea', gid: stopArea.Gid })}></TravelSearchItem>
      ))}
    </div>
  );
}