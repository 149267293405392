import React from 'react';
import * as styles from './badge.module.scss';

type BadgeProps = {
  icon?: JSX.Element;
  text: string;
  onClick?: () => void;
  children?: JSX.Element | JSX.Element[];
};

function Filled({ icon, text, onClick, children }: BadgeProps) {
  return (
    <button onClick={onClick} className={`${styles.badge} ${styles.filled}`}>
      {!!icon ? icon : null}
      <span>{text}</span>
      {children}
    </button>
  );
}

function Outlined({ icon, text, onClick, children }: BadgeProps) {
  return (
    <button onClick={onClick} className={`${styles.badge} ${styles.outlined}`}>
      {!!icon ? icon : null}
      <span>{text}</span>
      {children}
    </button>
  );
}

export const Badge = {
  Filled: (props: BadgeProps) => <Filled {...props}></Filled>,
  Outlined: (props: BadgeProps) => <Outlined {...props}></Outlined>
};