import { useEffect, useState } from 'react';
import { ActivityModel } from '../models/activity.model';
import { getActivityService } from '../services/activity.service';
import { TranslationService } from '../services/translation.service';
import { getTravelService, TravelSearchResult } from '../services/travel.service';
import useDebounce from './debounce.hook';

export default function useTravelSearch(query: string, translation: TranslationService, activities: ActivityModel[]) {
  const [searchResult, setSearchResult] = useState({ activities: [], stopAreas: [] } as TravelSearchResult);
  const debouncedQuery = useDebounce(query, 250);

  useEffect(() => {
    (async () => {
      const activityService = getActivityService();
      const travelService = getTravelService();
      
      await travelService.init();

      if(!query || !activityService || !travelService) {
        setSearchResult({ activities: [], stopAreas: [] });
        return;
      }

      setSearchResult(await travelService.search(query, translation.lang, activities, Object.values(travelService.stopAreas)));
    })();
  }, [debouncedQuery]);

  return searchResult;
}