import Footer from '@/src/components/footer/footer';
import Header from '@/src/components/header/header';
import IconInput from '@/src/components/icon-input/icon-input';
import Navbar from '@/src/components/navbar/navbar';
import { Subheader } from '@/src/components/subheader/subheader';
import { TranslationProvider } from '@/src/contexts/translation.context';
import { TranslationService } from '@/src/services/translation.service';
import { graphql, navigate, PageProps } from 'gatsby';
import React, { useEffect, useState } from 'react';
import * as styles from './book-ticket.module.scss';
import SendIcon from '@/assets/icons/input-icons/send.inline.svg';
import LocationIcon from '@/assets/icons/input-icons/location.inline.svg';
import { Badge } from '@/src/components/badge/badge';
import { BiCaretDown, BiSearchAlt, BiCalendarAlt } from 'react-icons/bi';
import { TravelItem } from './travel-item/travel-item';
import { getActivityService } from '@/src/services/activity.service';
import { ActivityModel } from '@/src/models/activity.model';
import useAuthStatus from '@/src/hooks/auth-status.hook';
import useTravelSearch from '@/src/hooks/travel-search.hook';
import { OnSelectEvent, TravelSearchDropdown } from '@/src/components/travel-search-dropdown/travel-search-dropdown';
import { getTravelService } from '@/src/services/travel.service';
import { BookedTravel, DepartureModel } from '@/src/models/departures.model';
import Spinner from '@/src/components/spinner/spinner';
import BottomSheet from '@/src/components/bottom-sheet/bottom-sheet';
import SignupFunnel from '@/src/components/signup-funnel/signup-funnel';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns/esm';
import { FavouriteActivities } from './favourite-activities/favourite-activities';
import objectHash from 'object-hash';
import RoadMap from '@/src/components/roadmap/roadmap';
import { Helmet } from 'react-helmet';

type ActivityQueryResult = {
  allActivity: {
    edges: {
      node: ActivityModel;
    }[];
  };
};
export default function BookTicketPage({ pageContext, data }: PageProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const [favouriteActivities, setFavouriteActivities] = useState([] as ActivityModel[]);
  const activityQueryResult = data as ActivityQueryResult;
  const { isAuthenticated } = useAuthStatus();
  const [fromQuery, setFromQuery] = useState('');
  const [toQuery, setToQuery] = useState('');
  const fromResult = useTravelSearch(fromQuery, translation, activityQueryResult.allActivity.edges.map(edge => edge.node));
  const toResult = useTravelSearch(toQuery, translation, activityQueryResult.allActivity.edges.map(edge => edge.node));
  const [selectedDepartureGid, setSelectedDepartureGid] = useState('');
  const [selectedArrivalGid, setSelectedArrivalGid] = useState('');
  const [selectedDepartureActivityId, setSelectedDepartureActivityId] = useState('');
  const [selectedArrivalActivityId, setSelectedArrivalActivityId] = useState('');
  const [timetableResult, setTimetableResult] = useState(null as DepartureModel[]);
  const [fromInputFocused, setFromInputFocused] = useState(false);
  const [toInputFocused, setToInputFocused] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [funnelOpen, setFunnelOpen] = useState(false);
  const [funnelActionKey, setFunnelActionKey] = useState('');
  const [travelDate, setTravelDate] = useState(new Date());
  const [bookedTrips, setBookedTrips] = useState([] as BookedTravel[]);
  const [bookedTripsIndexes, setBookedTripsIndexes] = useState([] as number[]);
  const [travelErrorMessage, setTravelErrorMessage] = useState('');
  const [showRoadMap, setShowRoadMap] = useState(false);

  const tabs = [
    {
      name: translation.translate('book_ticket.book_tickets'),
      href: `/${translation.lang}/book-ticket`
    },
    {
      name: translation.translate('book_ticket.upcoming_travel'),
      href: `/${translation.lang}/booked-trips`
    }
  ];

  const getTravelDateName = () => {
    const today = new Date();
    if(travelDate.getDate() === today.getDate() && travelDate.getMonth() === today.getMonth() && travelDate.getFullYear() === today.getFullYear()) {
      return translation.translate('book_ticket.book_today');
    } else {
      return format(travelDate, 'dd/MM/yyyy');
    }
  };

  const compareTrips = (a: BookedTravel, b: BookedTravel): boolean => {
    const aId = objectHash(a, { algorithm: 'md5' });
    const bId = objectHash(b, { algorithm: 'md5' });
    return aId === bId;
  };

  useEffect(() => {
    const activityService = getActivityService();
    activityService.init();
    const subscription = activityService.favouriteActivities$.subscribe(favouriteActivities => {
      const newFavouriteActivities = [];
      for(const favouriteActivity of favouriteActivities) {
        // Make sure that page query yields this activity id
        let hasActivity = false;
        let activity: ActivityModel;
        for(const a of activityQueryResult.allActivity.edges) {
          if(favouriteActivity === a.node.id) {
            hasActivity = true;
            activity = a.node;
            break;
          }
        }
        if(!hasActivity) continue;
        newFavouriteActivities.push(activity);
      }
      setFavouriteActivities(newFavouriteActivities);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const travelService = getTravelService();
    travelService.init();
    const subscription = travelService.bookedTrips$.subscribe(bookedTrips => {
      setBookedTrips(bookedTrips);
    });

    // If we have a stored timetable, display it
    const storedTimetable = travelService.storedTimetable;
    setTimetableResult(storedTimetable);
    setShowRoadMap(storedTimetable.length > 0);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const bookedIndexes = [];

    if(!timetableResult) {
      setBookedTripsIndexes(bookedIndexes);
      return;
    }

    let index = 0;
    for(const schedule of timetableResult) {
      let found = false;

      for(const bookedTrip of bookedTrips) {
        if(compareTrips(
          {
            date: format(travelDate, 'yyyy-MM-dd'),
            schedule: schedule
          },
          bookedTrip
        )) {
          found = true;
          break;
        }
      }

      if(found) {
        bookedIndexes.push(index);
      }
      index++;
    }

    setBookedTripsIndexes(bookedIndexes);
  }, [bookedTrips, timetableResult]);

  const handleFromOnSelect = (event: OnSelectEvent) => {
    if(event.targetType === 'stopArea') {
      setSelectedDepartureActivityId('');
      setSelectedDepartureGid(event.gid);
      const travelService = getTravelService();
      travelService.init().then(() => {
        const departure = travelService.stopAreas[event.gid];
        if(departure) {
          setFromQuery(departure.Name);
        }
      });
    } else if(event.targetType === 'activity') {
      const activityId = event.gid;
      const activity = activityQueryResult.allActivity.edges.map(edge => edge.node).find(a => a.id === activityId);
      if(activity) {
        setSelectedDepartureActivityId(activity.id);
        setSelectedDepartureGid(activity.stopareaGid);
        setFromQuery(activity.title[translation.lang]);
      }
    }
  };

  const handleToOnSelect = (event: OnSelectEvent) => {
    if(event.targetType === 'stopArea') {
      setSelectedArrivalActivityId('');
      setSelectedArrivalGid(event.gid);
      const travelService = getTravelService();
      travelService.init().then(() => {
        const arrival = travelService.stopAreas[event.gid];
        if(arrival) {
          setToQuery(arrival.Name);
        }
      });
    } else if(event.targetType === 'activity') {
      const activityId = event.gid;
      const activity = activityQueryResult.allActivity.edges.map(edge => edge.node).find(a => a.id === activityId);
      if(activity) {
        setSelectedArrivalActivityId(activity.id);
        setSelectedArrivalGid(activity.stopareaGid);
        setToQuery(activity.title[translation.lang]);
      }
    }
  };

  const findTimetable = async () => {
    const travelService = getTravelService();
    setTravelErrorMessage('');
    travelService.setStoredTimetable([]);
    setShowRoadMap(false);

    if(!selectedDepartureGid || !selectedArrivalGid) {
      setTravelErrorMessage(translation.translate('book_ticket.no_travel_points_error'));
      return;
    }

    if(selectedDepartureGid === selectedArrivalGid) {
      setTravelErrorMessage(translation.translate('book_ticket.same_travel_points_error'));
      return;
    }

    setSearchLoading(true);
    const timetable = await travelService.findTimetable(selectedDepartureGid, selectedArrivalGid, travelDate, selectedDepartureActivityId, selectedArrivalActivityId);
    travelService.setStoredTimetable(timetable);
    setTimetableResult(timetable);

    if(timetable.length > 0) {
      setShowRoadMap(true);
    }

    setSearchLoading(false);
  };

  const buyPass = async () => {
    if(isAuthenticated) {
      navigate(`/${translation.lang}/purchase/cart`);
    } else {
      setFunnelActionKey('buy_pass');
      setFunnelOpen(true);
    }
  };

  const bookTrip = async (schedule: DepartureModel) => {
    if(isAuthenticated) {
      const travelService = getTravelService();
      travelService.bookTrip(
        {
          date: format(travelDate, 'yyyy-MM-dd'),
          schedule,
        },
        selectedDepartureGid,
        selectedArrivalGid,
      );
    } else {
      setFunnelActionKey('book_trip');
      setFunnelOpen(true);
    }
  };

  const unbookTrip = async (schedule: DepartureModel) => {
    if(isAuthenticated) {
      const travelService = getTravelService();
      travelService.unbookTrip({
        date: format(travelDate, 'yyyy-MM-dd'),
        schedule
      });
    }
  };

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate('helmet.book_ticket_title')}</title>
        <meta name="description" content={translation.translate('helmet.book_ticket_description')} />
        <meta http-equiv="content-language" content={translation.lang} />
      </Helmet>
      <Header></Header>
      <div className="scrollable">
        <div className={`${styles.content} content`}>
          <Subheader.Tabs activeHref={`/${translation.lang}/book-ticket`} tabs={tabs}></Subheader.Tabs>
          <div className={styles.container}>
            <div className={styles.bookingContainer}>
              <div className={`app__wrapper`}>
                <h1 className={styles.heading}>{translation.translate('book_ticket.heading')}</h1>
                <p className={styles.text} dangerouslySetInnerHTML={{ __html: translation.translate('book_ticket.book_tickets_text') }}></p>
                {isAuthenticated ?
                  <div className={styles.favouriteActivitiesContainer}>
                    <FavouriteActivities
                      favouriteActivities={favouriteActivities}
                      onTravelFrom={activity => handleFromOnSelect({ targetType: 'activity', gid: activity.id })}
                      onTravelTo={activity => handleToOnSelect({ targetType: 'activity', gid: activity.id })}></FavouriteActivities>
                  </div>
                  : null}
                <div className={`${styles.destinationContainer}`}>
                  <div className={styles.fromInputContainer}>
                    <label htmlFor="from">{translation.translate('book_ticket.from')}:</label>
                    <IconInput
                      placeholder={translation.translate('book_ticket.from')}
                      icon={<div><SendIcon style={{ width: '1rem', height: '1rem' }} /></div>}
                      onFocus={() => setTimeout(() => setFromInputFocused(true), 100)}
                      onBlur={() => setTimeout(() => setFromInputFocused(false), 200)}
                      value={fromQuery}
                      onChange={event => setFromQuery(event.target.value)}></IconInput>
                    {fromResult.stopAreas.length + fromResult.activities.length > 0 ?
                      <TravelSearchDropdown
                        result={fromResult}
                        hasFocus={fromInputFocused}
                        onSelect={handleFromOnSelect}></TravelSearchDropdown>
                      : null}
                  </div>
                  <div>
                    <div className={styles.toInputContainer}>
                      <label htmlFor="to">{translation.translate('book_ticket.to')}:</label>
                      <IconInput
                        placeholder={translation.translate('book_ticket.to')}
                        icon={<LocationIcon />}
                        onFocus={() => setTimeout(() => setToInputFocused(true), 100)}
                        onBlur={() => setTimeout(() => setToInputFocused(false), 200)}
                        value={toQuery}
                        onChange={event => setToQuery(event.target.value)}></IconInput>
                      {toResult.stopAreas.length + toResult.activities.length > 0 ?
                        <TravelSearchDropdown
                          result={toResult}
                          hasFocus={toInputFocused}
                          onSelect={handleToOnSelect}></TravelSearchDropdown>
                        : null}
                    </div>
                  </div>
                </div>
                <div className={styles.filterContainer}>
                  <span>
                    {getTravelDateName()}
                    <BiCaretDown></BiCaretDown>
                    <DatePicker
                      name="travelDate"
                      dateFormat="dd/MM/yyyy"
                      selected={travelDate}
                      onChange={(date) => setTravelDate(date as Date)}
                      minDate={new Date()}
                      customInput={<div className={styles.headlessDatepicker}><BiCalendarAlt height={18} width={18} /></div>}></DatePicker>
                  </span>
                  <Badge.Filled
                    text={translation.translate('book_ticket.search_button')}
                    icon={<BiSearchAlt />}
                    onClick={() => !searchLoading && findTimetable()}></Badge.Filled>
                </div>
                {travelErrorMessage ?
                  <div className={styles.errorMessage}>
                    {travelErrorMessage}
                  </div>
                  : null}
              </div>
            </div>
          </div>
          {showRoadMap &&
            <div className={styles.travelRoadMap}>
              <RoadMap translation={translation} timetable={timetableResult}></RoadMap>
            </div>
          }
          <div className={styles.travelContainer}>
            {timetableResult && !searchLoading && timetableResult.length > 0 ?
              timetableResult.map((schedule, index) => {
                const parts = [];

                for(const part of schedule.parts) {
                  const departure = {
                    departureTime: part.Destination.Time,
                    departureName: part.SequenceFragment[0].StopPoint.Name,
                  };
                  const destination = {
                    destinationTime: part.SequenceFragment[part.SequenceFragment.length - 1].Time,
                    destinationName: part.SequenceFragment[part.SequenceFragment.length - 1].StopPoint.Name,
                  };
                  const tripInformation = part.ContractorName;
                  const tripDuration = part.SequenceFragment[part.SequenceFragment.length - 1].SecondsSinceStart;
                  const stops = [];
                  const transportMode = part.TransportModeCode;

                  if(part.SequenceFragment.length >= 3) {
                    for(let i = 1; i < part.SequenceFragment.length - 1; i++) {
                      stops.push({
                        name: part.SequenceFragment[i].StopPoint.Name,
                        time: part.SequenceFragment[i].Time,
                      });
                    }
                  }

                  parts.push({
                    gid: part.Gid,
                    departure,
                    destination,
                    tripInformation,
                    tripDuration,
                    stops,
                    transportMode,
                  });
                }

                const departureDate = new Date(schedule.departure);
                const now = new Date();
                const disabled = now.getTime() > departureDate.getTime();


                return (
                  <TravelItem
                    key={index}
                    disabled={disabled}
                    parts={parts}
                    onBuyClick={buyPass}
                    onBookClick={() => bookTrip(schedule)}
                    onUnbookClick={() => unbookTrip(schedule)}
                    unbookHidden={!bookedTripsIndexes.includes(index)}
                    bookHidden={bookedTripsIndexes.includes(index)}></TravelItem>
                );
              })
              : null}
            {!timetableResult && !searchLoading ?
              <p className={styles.timetableText}>{translation.translate('book_ticket.search_funnel_text')}</p>
              : null}
            {timetableResult && !searchLoading && timetableResult.length <= 0 ?
              <p className={styles.timetableText}>{translation.translate('book_ticket.search_no_results')}</p>
              : null}
            {searchLoading ? <Spinner dark={true}></Spinner> : null}
          </div>
        </div>
        <Footer className={styles.footer}></Footer>
      </div>
      <Navbar></Navbar>
      <BottomSheet open={funnelOpen}>
        <SignupFunnel
          onClose={() => setFunnelOpen(false)}
          actionKey={funnelActionKey}></SignupFunnel>
      </BottomSheet>
    </TranslationProvider>
  );
}

export const query = graphql`
  query {
    allActivity {
      edges {
        node {
          id
          title {
            sv
            fi
            en
          }
          stopareaGid
        }
      }
    }
  }
`;