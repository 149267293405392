import React, { useState } from 'react';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';
import * as styles from './accordion.module.scss';
import StarFilledIcon from '@/assets/icons/star-filled.inline.svg';

type AccordionProps = {
  heading: string;
  children?: JSX.Element | JSX.Element[];
};

export default function Accordion({ heading, children }: AccordionProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button onClick={() => setOpen(!open)} className={`${styles.heading} ${open ? styles.headingOpen : ''}`}>
        <div>
          <StarFilledIcon width={15} height={15} className={styles.star}></StarFilledIcon>
          {heading}
        </div>
        {open ? <BiChevronDown size={25}></BiChevronDown> : <BiChevronRight size={25}></BiChevronRight>}
      </button>
      <div className={`${styles.panel} ${open ? styles.panelOpen : ''}`}>
        {children}
      </div>
    </>
  );
}