import firebase from 'gatsby-plugin-firebase';
import hash from 'object-hash';
import buildUrl from 'build-url';

type GetImageUrlOptions = {
  departureAreaGid: string,
  arrivalAreaGid: string,
  maps: any,
  departurePoint: {
    lat: number,
    lng: number,
  },
  arrivalPoint: {
    lat: number,
    lng: number,
  },
  formattedWaypoints: any[]
}

class DirectionsService {
  async getImageUrl({ departureAreaGid, arrivalAreaGid, maps, departurePoint, arrivalPoint, formattedWaypoints }: GetImageUrlOptions): Promise<any> {
    const travelId = hash({ departureAreaGid, arrivalAreaGid }, { algorithm: 'md5' });
    const cacheRow = await firebase.firestore().collection('cachedDirections').doc(travelId).get();

    if(cacheRow.exists) {
      return cacheRow.data().result;
    } else {
      const directionsService = new maps.DirectionsService();

      const route = {
        origin: new maps.LatLng(departurePoint.lat, departurePoint.lng),
        destination: new maps.LatLng(arrivalPoint.lat, arrivalPoint.lng),
        travelMode: 'DRIVING',
        waypoints: formattedWaypoints
      }
      
      const result = await new Promise((resolve) => {
        directionsService.route(route, (result, status) => {
          if(status == 'OK') {
            resolve(result);
          }
        })
      });
      
      firebase.firestore().collection('cachedDirections').doc(travelId).set({
        createdAt: new Date(),
        result: JSON.stringify(result)
      });

      return JSON.stringify(result);
    }
  }

}

const directionsServiceInstance = new DirectionsService();
export function getDirectionsService() {
  return directionsServiceInstance;
}