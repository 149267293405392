// extracted by mini-css-extract-plugin
export var heading = "book-ticket-module--heading--1wnk1";
export var text = "book-ticket-module--text--2weyR";
export var content = "book-ticket-module--content--2_CvW";
export var container = "book-ticket-module--container--q-hC-";
export var favouriteActivitiesContainer = "book-ticket-module--favouriteActivitiesContainer--1BlTe";
export var destinationContainer = "book-ticket-module--destination-container--eKTCP";
export var fromInputContainer = "book-ticket-module--from-input-container--_UMPJ";
export var toInputContainer = "book-ticket-module--to-input-container--2eRiL";
export var filterContainer = "book-ticket-module--filter-container--2OG2L";
export var headlessDatepicker = "book-ticket-module--headless-datepicker--285qc";
export var travelContainer = "book-ticket-module--travel-container--5QgIj";
export var timetableText = "book-ticket-module--timetable-text--3SwYE";
export var footer = "book-ticket-module--footer--3f5td";
export var bookingContainer = "book-ticket-module--booking-container--3YyLc";
export var errorMessage = "book-ticket-module--error-message--3EWrn";
export var travelRoadMap = "book-ticket-module--travel-road-map--2FYBb";