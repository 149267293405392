import Accordion from '@/src/components/accordion/accordion';
import { useTranslation } from '@/src/contexts/translation.context';
import { ActivityModel } from '@/src/models/activity.model';
import React from 'react';
import * as styles from './favourite-activities.module.scss';
import SendIcon from '@/assets/icons/input-icons/send.inline.svg';
import LocationIcon from '@/assets/icons/input-icons/location.inline.svg';

type FavouriteActivitiesProps = {
  favouriteActivities: ActivityModel[];
  onTravelFrom?: (activity: ActivityModel) => any;
  onTravelTo?: (activity: ActivityModel) => any;
};

export function FavouriteActivities({ favouriteActivities, onTravelFrom, onTravelTo }: FavouriteActivitiesProps) {
  const translation = useTranslation();

  return (
    <Accordion heading={translation.translate('book_ticket.favourite_activities')}>
      {favouriteActivities.map(activity => (
        <div key={activity.id} className={styles.container}>
          <p>{activity.title[translation.lang]}</p>
          <div className={styles.buttons}>
            <button onClick={() => onTravelFrom(activity)}><SendIcon width={14} height={14} />{translation.translate('book_ticket.favourite_travel_from')}</button>
            <button onClick={() => onTravelTo(activity)}><LocationIcon width={14} height={14} />{translation.translate('book_ticket.favourite_travel_to')}</button>
          </div>
        </div>
      ))}
    </Accordion>
  );
}